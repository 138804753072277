export default {
  server: 'wss://router.equery.cherg.net/',
  another_server: 'https://s1.main.ru.cherg.net/',
  servid: '5661',
  servname: 'Відділ ЦНАП м. Херсон',
  wait_time: { // конфиг таймаутов
    current_queue: 2500, // текущая очередь
    current_client: 2500,
    deferred_queue: 2500,
    waiting_list: 0,
    count_message: 0,
    message: 0,
    reg_list: 2500,
    server_time: 2500,
    auto_time: 1000,
    beep_tab: 0,
    intercom_list: 0
  },
  registration_tab: true,
  waiting_tab: false,
  current_tab: true,
  deferred_tab: true,
  graf_tab: false,
  plan_time: true,
  viber_chat: false,
  intercom_chat: false,
  lang: 'ua',
  additional_services_time: [
    {
      _name: '5 хвилин',
      _time: '300'
    },
    {
      _name: '10 хвилин',
      _time: '600'
    },
    {
      _name: '15 хвилин',
      _time: '900'
    },
    {
      _name: '20 хвилин',
      _time: '1200'
    }
  ]
}
